<template>
  <div class="apply-info">
    <div class="banner">
      <div class="info">
        <div>{{ user.customerName }}</div>
        <div>{{ pro.productName }}</div>
      </div>
      <div class="apl-info">
        <div class="a-i-item">
          <div class="val">{{ orderInfo.applyAmount }}</div>
          <div class="name">申请金额</div>
        </div>
        <div class="a-i-item">
          <div class="val">{{ orderInfo.applyTerm }}</div>
          <div class="name">申请期数</div>
        </div>
      </div>
    </div>

    <div class="page-block apply-info">
      <div class="info-title">申请人信息</div>
      <van-cell
        v-for="(item, i) in applyInfo"
        :title="item.name"
        :key="i"
        :value="item.value"
      />
    </div>

    <div class="page-block replenish">
      <div class="info-title">补充信息</div>
      <!-- 目前所有入口都可点击 -->
      <div v-for="(item, i) in replenishInfo" :key="i">
        <!-- v-if="item.show" -->
        <van-cell :title="item.name" is-link @click="itemClick(item)">
          <template #default>
            <span :class="item.status ? 'suc' : 'usual'">{{
              item.status ? "已完成" : "去完成"
            }}</span>
          </template>
        </van-cell>
      </div>
    </div>
  </div>
</template>
<script>
import { getLastOrder } from "@/api/login";
import { orderDetailInfo } from "@/api/apply";
import { stringHypose } from "@/utils/tool";
import { mapGetters, mapActions } from "vuex";
// 个人信息、车辆信息、进件材料显示判断
const unshows = ["-1", "-2", "-3", "10", "20", "160"];
function threeItem(appStatus) {
  return !unshows.includes(appStatus);
}
export default {
  data() {
    return {
      bizNo: "",
      loading: false,
      user: {},
      pro: {},
      orderInfo: {}
      // replenishInfo: [],
      // applyInfo: [
      // ]
    };
  },
  computed: {
    applyInfo() {
      const { name, mobile, certificateNo } = this.orderInfo;
      return [
        {
          name: "申请人",
          value: name
        },
        {
          name: "手机号",
          value: stringHypose(mobile)
        },
        {
          name: "身份证号",
          value: stringHypose(certificateNo, [6, 14])
        }
      ];
    },
    replenishInfo() {
      const {
        appStatus,
        certificationStatus,
        personalInformationStatus,
        vehicleInformationStatus,
        incomingMaterialStatus,
        guarantorStatus,
        livingStatus
      } = this.orderInfo;
      return [
        // 一定展示
        {
          name: "身份认证",
          status: "suc",
          show: true
          // show: ({certificationStatus}) => certificationStatus
        },
        // 风控预审通过展示
        {
          name: "个人信息",
          status: personalInformationStatus,
          show: threeItem(appStatus),
          click: () => {
            if (personalInformationStatus) return;
            this.$router.push("/personal-info");
          }
        },
        // 风控预审通过展示
        {
          name: "车辆信息",
          status: vehicleInformationStatus,
          show: threeItem(appStatus),
          click: () => {
            if (vehicleInformationStatus) return;
            this.$router.push("/vehicle-info");
          }
        },
        // 风控预审通过展示
        {
          name: "进件材料",
          status: incomingMaterialStatus,
          show: threeItem(appStatus),
          click: () => {
            if (incomingMaterialStatus) return;
            this.$router.push("/materials-info");
          }
        },
        // 存在担保人展示
        {
          name: "担保人信息",
          status: false,
          show: guarantorStatus
        },
        // 平台授信成功展示
        {
          name: "还款卡绑定",
          status: false,
          show: false
        },
        // 活体检测不通过展示
        {
          name: "活体检测",
          status: false,
          show: !livingStatus,
          click: () => {
            if (livingStatus) return;
            this.$router.push("/identity-portrait-face");
          }
        }
      ];
    }
  },
  created() {
    const data = this.getUserOrderInfo();
    const user = this.getUserInfo();
    const pro = this.getProductInfo();
    this.user = user || {};
    this.pro = pro || {};
    if (!data || !data.bizNo) this.getLastOrder();
    else {
      this.bizNo = data.bizNo;
      this.getApplyInfo();
    }
  },
  methods: {
    ...mapGetters(["getProductInfo", "getUserOrderInfo", "getUserInfo"]),
    ...mapActions(["setUserOrderInfo"]),
    itemClick(item) {
      if (item.click) item.click();
    },
    async getApplyInfo() {
      try {
        this.loading = true;
        const { data } = await orderDetailInfo(this.bizNo);
        this.$set(this, "orderInfo", data);
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取最近订单，没有订单后端会生成预进件订单
    async getLastOrder() {
      try {
        // const info = this.getProductInfo();
        const { merchantNo, productNo } = this.pro;
        const { data } = await getLastOrder(productNo, merchantNo);
        this.setUserOrderInfo(data);
        this.bizNo = data.bizNo || "";
        this.getApplyInfo();
      } catch (error) {
        this.$errMsg(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.apply-info {
  .banner {
    width: 100%;
    height: 286px;
    background: url("/images/index/背景.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 16px 24px;
    box-sizing: border-box;
    color: #fff;
    font-weight: bold;
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 32px;
      margin-top: 60px;
    }
    .apl-info {
      margin: 0 auto;
      width: 80vw;
      display: flex;
      margin-top: 48px;
      align-items: center;
      justify-content: center;
      .a-i-item {
        width: 50%;
        text-align: center;
        .val {
          font-size: 58px;
          &::after {
            content: "元";
            font-size: 12px;
            margin-left: 10px;
          }
        }
        &:last-child .val::after {
          content: "期";
        }
        .name {
          font-size: 12px;
        }
      }
    }
  }
  .page-block {
    margin: 40px 32px;
    border-radius: 8px;
    position: relative;
    padding-top: 40px;
    box-shadow: 0 0 10px #aaa6;
    .info-title {
      width: 368px;
      height: 64px;
      margin-top: -48px;
      background: url("/images/login/title@2x.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 64px;
      font-size: 32px;
      font-weight: 600;
      margin-left: calc((100% - 368px) / 2);
      color: #ffffff;
    }
    .van-cell {
      .suc {
        color: #52c41a;
      }
      .warn {
        color: #faad14;
      }
      .usual {
        color: #1890ff;
      }
    }
  }
}
</style>
